<template>
  <div>
    <!-- <div class="mbxtit">
      <div class="w1400">首页&nbsp;&nbsp;&gt;&nbsp;&nbsp;<a href="">行情价格</a>&nbsp;&nbsp;&gt;&nbsp;&nbsp;类目名称&nbsp;&nbsp;&gt;&nbsp;&nbsp;<a href="">品种名称</a></div>
    </div> -->
    <ImgNav />
    <DetailNavBar :title="subName" />
    <div class="w1400">
      <div class="fengleilist clearfix">
        <div class="flistleft">
          <div class="allbody clearfix">
            <div class="leftbody">
              <p><span>{{baseType}}</span><span>{{subName}}</span></p>
              <p><span>详细地址：{{subAddress || '暂无地址'}}</span><span> 联系电话：{{subTel}}</span></p>
            </div>
            <div class="rightbody">
              <span  v-bind:class="fllow ? '' : 'active'"  @click="subscriptionclick">{{fllow ? "取消关注" :"+加入关注"}}</span>
              <!--<span v-else>+加入关注</span>-->
            </div>
          </div>
          <div class="tablebottom">
            <div class="timechose clearfix">
              <ul>
                <li  :class="{'active':current==0}" @click="tjbtnclick"><span>调价</span></li>
                <li  :class="{'active':current==1}" @click="newsbtnclick"><span>新闻</span></li>
              </ul>
              <div class="timeself">
                <DatePicker  v-model="timeall"  format="yyyy-MM-dd" type="daterange" placement="bottom-end"  @on-change="mate_date_Change" placeholder="请选择日期" style="width: 250px;height: 34px;"></DatePicker>
              </div>
            </div>
            <ul class="tiaojialist" v-show="tiaojia">
              <li class="tiaojai" v-for="(iteam ,index) in tiaojiaarr">
                  <div class="clearfix"><span>{{iteam.title}}</span><span>{{iteam.date}}</span></div>
                   <p v-html="iteam.content"></p>
              </li>
            </ul>
            <ul class="tiaojialist" v-show="news">
              <!--<li class="news clearfix">-->
                <!--<img src="~assets/img/hqjg.png" style="width: 180px;height: 120px;float: left"/>-->
                <!--<div class="fxzd_right">-->
                  <!--<h2>废旧策略：废铜交易走弱 废铝继续跟跌</h2>-->
                  <!--<p title="">新手怎样投资原油期货，原油投资新手都憧憬的变化个过程中我们需要多多学习掌握一些技巧对资原油期货，原油投资新手都憧憬的变化个过程中我们需要多多学习掌握一些技巧对于新手来说，每次交易不宜贪心，适当的练 .资原油期货，原油投资新手都憧憬的变化个过程中我们需要多多学习掌握一些技巧对于新手来说，每次交易不宜贪心，适当的练 .资原油期货，原油投资新手都憧憬的变化个过程中我们需要多多学习掌握一些技巧对于新手来说，每次交易不宜贪心，适当的练 .于新手来说，每次交易不宜贪心，适当的练 .</p>-->
                  <!--<span>2016/02/23 16:40</span>-->
                <!--</div>-->
              <!--</li>-->
            </ul>
            <Page :total="page" @on-change="changenext"  show-elevator style="margin:22px 0 180px 0;float: right"></Page>
          </div>
        </div>
        <div class="flistright">
            <div class="righttop">
              <p>APP扫码下载</p>
              <img src="~assets/img/public/QRcode_app.png" style="width: 150px;height: 150px;margin: 0 auto;display: block"/>
              <span>随时随地 关注行情</span>
            </div>
          <div  class="rightbottom">
            <p>最新调价</p>
            <div @click.stop="handleImgClick($event)">
              <!--<vue-seamless-scroll :data="fgtjarrs" style="height:412px;overflow: hidden;border: 1px solid #E6EBF5;" :class-option="defaultOption">-->
                <ul class="tiaojiabody" style="height:380px;overflow: hidden;border: 1px solid #E6EBF5;">
                  <li class="clearfix" v-for="(fgtjarr, index) in fgtjarrs" :id="fgtjarr.subId" :title="fgtjarr.title">
                    <span class="fl"></span> [{{fgtjarr.name}}]{{fgtjarr.title}}
                  </li>
                </ul>
              <!--</vue-seamless-scroll>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import NavHead from '@/components/content/mainTabbar/NavHead'
  import LogoBar from '@/components/content/mainTabbar/LogoBar'
  import {getPriceNewsApi,subscriptionApi,getSlpDetailApi} from 'network/home'
export default {
  name: 'scrapdetail',
  data() {
    return {
      token:"",
      setVip:"",
      subId:'',//id
      fllow:"",//关注
      current:0,//调价和新闻
      starttime:"",//开始时间
      endtime:"",//结束时间
      baseType:"",
      subName:"",
      subAddress:"",
      subTel:"",
      page:1,//默认第一页
      count:0,//总页数
      subscriptionStatus:1,
      tiaojia:true,
      tiaojiaarr:[],//返回内容
      news:false,
      timeall:[],
      fgtjarrs:[],//热门发布
    }
  },
  components: {
    NavHead,
    LogoBar
  },
  computed: {
    defaultOption () {
      return {
        step: 0.6, // 数值越大速度滚动越快
        // limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        // hoverStop: true, // 是否开启鼠标悬停stop
        // direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        // singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        // waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    },
  },
  created () {
    this.subId=this.$route.query.subId;
    this.token = this.$store.state.token
    this.setVip=localStorage.getItem('setVip')
    this.getLastMonth();
    this.getPriceNews();
    this.getPriceNewslist();
    this.getSlpDetail();
  },
  mounted(){

  },
  methods: {
    changes(index){
      this.current = index;
    },
    // 选择日期触发
    mate_date_Change(e) {
      this.starttime = e[0];
      this.endtime = e[1];
      this.getPriceNews();
    },
    getLastMonth() {
      var now = new Date();
      var year = now.getFullYear();
      var month = now.getMonth() + 1;//0-11表示1-12月
      var day = now.getDate();
      var dateObj = {};
      dateObj.now = year + '-' + month + '-' + day;
      var nowMonthDay = new Date(year, month, 0).getDate();    //当前月的总天数
      if(month - 1 <= 0){ //如果是1月，年数往前推一年<br>　　　　
        dateObj.last = (year - 1) + '-' + 12 + '-' + day;
      }else{
        var lastMonthDay = new Date(year, (parseInt(month) - 1), 0).getDate();
        if(lastMonthDay < day){    //1个月前所在月的总天数小于现在的天日期
          if(day < nowMonthDay){        //当前天日期小于当前月总天数
            dateObj.last = year + '-' + (month - 1) + '-' + (lastMonthDay - (nowMonthDay - day));
          }else{
            dateObj.last = year + '-' + (month - 1) + '-' + lastMonthDay;
          }
        }else{
          dateObj.last = year + '-' + (month - 1) + '-' + day;
        }
      }
      this.starttime=dateObj.last
      this.endtime=dateObj.now
      this.timeall=[dateObj.last,dateObj.now]
    },
    // 获取废钢调价
    getPriceNews(){
      let data = {
        num:this.page,
        size:10,
        subId:this.subId,
        beginDate:this.starttime,
        endDate:this.endtime
      }
      getPriceNewsApi(data).then((res) => {
        if(res.code==0){
          this.tiaojiaarr=res.data;
        }else{
        this.$Message.warning(res.errInfo)
        }
    })
    },
    changenext(page){
      this.page=page
      this.getPriceNews();
    },
    subscriptionclick(){
      let data = {
        subId:this.subId,
        subscriptionStatus:this.subscriptionStatus
      }
      subscriptionApi(data).then((res) => {
        if(res.code==0){
          if(this.subscriptionStatus==0){
            this.fllow=null;
            this.subscriptionStatus=1
          }else{
            this.fllow=1;
            this.subscriptionStatus=0
          }
        this.$Message.success(res.errInfo);
        }else{
        this.$Message.warning(res.errInfo)
        }
    })
    },
    getSlpDetail(){
      let data = {
        subId:this.subId,
      }
      getSlpDetailApi(data).then((res) => {
        if(res.code==0){
        this.fllow=res.data.gps;
        this.baseType=res.data.baseType;
        this.subName=res.data.subName;
        this.subAddress=res.data.subAddress;
        this.subTel=res.data.subTel;
        if(res.data.gps){
          this.subscriptionStatus=0
        }else{
          this.subscriptionStatus=1
        }
        }else{
          this.$Message.warning(res.errInfo)
        }
    })
    },
    tjbtnclick(){
      this.page=1;
      this.current=0;
      this.tiaojia=true;
      this.news=false;
    },
    newsbtnclick(){
      this.page=1;
      this.current=1;
      this.tiaojia=false;
      this.news=true;
    },
    handleImgClick(e) {
      let id = e.target.id;
      if(this.token){
        this.$router.push({path:'/scrapdetail',query: {subId:id}})
        this.$router.go(0)
      }else{
        this.$router.push({ path:'/login' })
      }
    },
    // 获取废钢调价
    getPriceNewslist(){
      let data = {
        num:1,
        size:10
      }
      getPriceNewsApi(data).then((res) => {
        if(res.code==0){
        this.fgtjarrs=res.data
      }else{
        this.$Message.warning(res.errInfo)
      }
    })
    },
  }
}
</script>

<style scoped>
 .mbxtit{
   width: 100%;
   height: 40px;
   background:#E6EBF5;
   font-size: 14px;
   line-height: 40px;
 }
  .flistleft{
    width: 800px;
    float: left;
  }
 .flistleft .allbody{
   position: relative;
   border: 1px solid #E6EBF5;
   margin: 30px 0;
   font-size: 14px;
   padding: 20px;
 }
 .flistleft .leftbody{
   float: left;
   width: 500px;
   
 }
 .flistleft .leftbody span{
   display: inline-block;
   line-height: 35px;
   /* border: 1px solid red; */
 }
 .flistleft .leftbody p:nth-child(1){
   color: #25449A;
   margin-bottom: 5px;
 }
 .flistleft .leftbody p:nth-child(1) span:nth-child(1){
   background: #DCEDFF;
   line-height: 24px;
   padding: 0 4px;
   border-radius: 3px;
   margin-right: 7px;
 }
 .flistleft .leftbody p:nth-child(1) span:nth-child(2){
   /* font-size: 18px; */
   font-weight: bold;
   transform: scale(1.15);
   margin-left: 15px;
 }
 .flistleft .leftbody p:nth-child(2){
   line-height: 30px;
   font-size: 16px;
 }
 .flistleft .leftbody p:nth-child(2) span:nth-child(1){
   margin-right: 45px;
 }
 .flistleft .leftbody p:nth-child(2) span:nth-child(2){
   position: relative;
   top: 0px;
 }
 .flistleft .rightbody{
   position: absolute;
   right: 20px;
   bottom: 20px;
 }
 .flistleft .rightbody span{
   display: block;
   text-align: center;
   font-size: 14px;
 }
 .flistleft .rightbody span{
   width: 80px;
   height: 32px;
   text-align: center;
   line-height: 31px;
   border: 1px solid #25449A;
   border-radius: 3px;
   margin: 0 auto;
   cursor: pointer;
   color: #25449A;
   font-size: 14px;
 }
 .flistleft .rightbody span.active{
   background:#25449A ;
  color: #fff;
 }
  .flistright{
    width: 350px;
    float: right;
  }
.flistright .righttop{
background: #25449A;
  border-radius: 5px;
  margin-top: 38px;
  overflow: hidden;
  }
.flistright .righttop p{
  text-align: center;
  line-height: 84px;
  font-size: 18px;
  color: #fff;
}
.flistright .righttop span{
  display: block;
  font-size: 18px;
  color: #303133;
  line-height: 60px;
  text-align: center;
  background: #D9EBFF;
  margin-top: 30px;
}
.flistright .rightbottom{
  margin-top: 23px;
}
.flistright .rightbottom p{
  line-height: 40px;
  background: #E6EBF5;
  border-left: 3px solid #25449A;
  font-size: 15px;
  padding-left: 18px;
}
 .tablebottom{
   margin-bottom: 210px;
 }
.tablebottom .timechose{
border-bottom: 1px solid #E6EBF5;
  cursor: pointer;
}
 .tablebottom  .timeself{
  float: right;
}
 .tablebottom .timechose ul{
   float: left;
 }
 .tablebottom .timechose ul li{
   width: 66px;
   float: left;
   /* font-size: 18px; */
   text-align: center;
   line-height: 46px;
 }
  .tablebottom .timechose ul li span{
    display: inline-block;
   transform: scale(1.3);
   font-weight: 700;
 }
 .tablebottom .timechose ul li.active{
   position: relative;
   top: 1px;
   color: #25449A;
   border-bottom: 2px solid #25449A;
 }
 .tablebottom .tiaojialist{
   border: 1px solid #E6EBF5;
   margin-top: 30px;
 }
 .tablebottom .tiaojialist .tiaojai{
   margin: 10px 20px 20px;
   border-bottom:1px solid #E6EBF5 ;
 }
 .tablebottom .tiaojialist .tiaojai div{
    line-height: 46px;
 }
 .tablebottom .tiaojialist .tiaojai div span:nth-child(1){
   font-size:16px ;
   float: left;
   color: black;
   font-weight: bold;
 }
 .tablebottom .tiaojialist .tiaojai div span:nth-child(2){
   font-size:14px ;
   float: right;
   color: #666666;
 }
 .tablebottom .tiaojialist .tiaojai p{
   line-height: 38px;
   font-size: 14px;
   color: #333;
 }
 .tablebottom .tiaojialist .news{
    margin: 20px;
   padding-bottom: 30px;
   border-bottom: 1px solid #E6EBF5;
 }
 .tablebottom .tiaojialist .news .fxzd_right{
   float: left;
   margin-left: 18px;
   width: 635px;
 }
 .tablebottom .tiaojialist .news .fxzd_right h2{
   font-size: 16px;
   color: black;
   line-height: 30px;
 }
 .tablebottom .tiaojialist .news .fxzd_right p{
   font-size: 14px;
   line-height: 30px;
   color: #333333;
   height: 68px;
   display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
   overflow: hidden;
 }
 .tablebottom .tiaojialist .news .fxzd_right span{
   display: block;
   font-size: 14px;
   color: #666;
 }
 .tiaojiabody{
   height: 380px;
   padding: 0px 8px;
   font-size: 14px;
   overflow: hidden;
 }
 .tiaojiabody li{
   line-height: 38px;
   cursor: pointer;
   width: 300px;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   list-style: disc;
 }
 .tiaojiabody li:hover{
   color: #25449A;
   text-decoration: underline;
 }
 .tiaojiabody li span.fl:before {
   content: ".";
   width: 4px;
   height: 4px;
   display: inline-block;
   border-radius: 50%;
   background: #000;
   vertical-align: middle;
   margin-right: 15px;
 }
</style>
<style>

</style>
