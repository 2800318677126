import { request } from './request'

// 首页废钢调价
export function getPriceNewsApi(data) {
  return request({
    url: '/txapi/message/getPriceNews',
    method: 'get',
    params: data
  })
}
//调价详情
export function getSlpDetailApi(data) {
  return request({
    url: '/txapi/message/getSlpDetail',
    method: 'get',
    params: data
  })
}
//首页物资拍卖。分析指导。行业资讯
export function getAuctionNewsApi(data) {
  return request({
    url: '/txapi/message/getAuctionNews',
    method: 'get',
    params: data
  })
}
//首页供求市场
export function getItemsApi(data) {
  return request({
    url: '/txapi/tranaction/getItems',
    method: 'get',
    params: data
  })
}
//首页成交动态
export function getUserOrdersApi(data) {
  return request({
    url: '/txapi/tranaction/getUserOrders',
    method: 'get',
    params: data
  })
}
// //首页行情价格
export function selectMarketPriceHomePageApi(data) {
  return request({
    url: '/txapi/marketPrice/selectMarketPriceHomePage',
    method: 'get',
    params: data
  })
}
// //行情价格类目
export function getGroupApi(data) {
  return request({
    url: '/txapi/marketPrice/getGroup',
    method: 'get',
    params: data
  })
}
//首页车辆动态地图
export function homeInfo(data) {
  return request({
    url: '/txapi/tranaction/homeInfoApi',
    method: 'get',
    params: data
  })
}
//关注接口
export function subscriptionApi(data) {
  return request({
    url: '/txapi/message/subscription',
    method: 'post',
    data: data
  })
}
//行情价格-我的关注接口
export function selectMarketPriceSubscriptionApi(data) {
  return request({
    url: '/txapi/marketPrice/selectMarketPriceSubscription',
    method: 'get',
    params: data
  })
}
//获取子分类以及子分类下面的产品
export function selectMarketPriceApi(data) {
  return request({
    url: '/txapi/marketPrice/selectMarketPrice',
    method: 'get',
    params: data
  })
}
// // 获取筛选条件（地区）
// export function getAuctionNewsMenuListProvinceApi(data) {
//   return request({
//     url: '/txapi/message/getAuctionNewsMenuListProvince',
//     method: 'get',
//     params: data
//   })
// }
// 获取筛选条件（地区）
export function getSlpProvinceApi(data) {
  return request({
    url: '/txapi/message/getSlpProvince',
    method: 'get',
    params: data
  })
}
// 行情价格表格
export function selectMarketPriceHistoryApi(data) {
  return request({
    url: '/txapi/marketPrice/selectMarketPriceHistory',
    method: 'get',
    params: data
  })
}
// 行情价格柱状图
export function selectMpHistoryChartApi(data) {
  return request({
    url: '/txapi/marketPrice/selectMpHistoryChart',
    method: 'get',
    params: data
  })
}
// 行情价格关注
export function selectMpDetailApi(data) {
  return request({
    url: '/txapi/marketPrice/selectMpDetail',
    method: 'get',
    params: data
  })
}
// 行情价格关注取消关注操作
export function focusOrCancelProApi(data) {
  return request({
    url: '/txapi/marketPrice/focusOrCancelPro',
    method: 'post',
    params: data
  })
}
// 废钢调价全部信息学
export function getSteelScrapApi(data) {
  return request({
    url: '/txapi/message/getSteelScrap',
    method: 'post',
    params: data
  })
}
